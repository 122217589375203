import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import GlobalContext from 'context/GlobalContext';
import * as deliveryActions from 'actions';
import { useTranslation } from 'react-i18next';
import {
  DELIVERY_STATUS_RECEIVED,
  DELIVERY_TIME_WINDOW_PREFIX,
  TIMEZONE_ABBREVIATIONS,
  DELIVERY_TAG_CLASS,
  DELIVERY_DATE_FORMAT
} from 'shared/constants';
import convertToTimezone from 'util/convertToTimezone';
import downloadPDF from 'util/downloadPdf';
import titleCase from 'util/titleCase';
import * as Authenticator from 'Authenticator';
import DeliveryDetailsPageComponent from './components/DeliveryDetailsPageComponent';

const lastUpdateTimeInterval = 5000;
const mapRefreshInterval = 60 * 5 * 1000;

let refreshTimeUpdate = null;
let mapUpdate = null;
const dateFormat = 'hh:mm A';

const convertDeliveryTimeToTimestamp = (timestamp, timeZone) => {
  return convertToTimezone(timestamp, timeZone, dateFormat);
};
const getIsTruckArriving = deliveryStatus =>
  deliveryStatus === DELIVERY_STATUS_RECEIVED.ON_TIME ||
  deliveryStatus === DELIVERY_STATUS_RECEIVED.UPDATED_WINDOW ||
  deliveryStatus === DELIVERY_STATUS_RECEIVED.SCHEDULED ||
  deliveryStatus === DELIVERY_STATUS_RECEIVED.DELAYED;

const getETAString = (timeWindow, timeZone) => {
  const begETAWindow = convertDeliveryTimeToTimestamp(timeWindow?.from, timeZone);
  const endETAWindow = convertDeliveryTimeToTimestamp(timeWindow?.to, timeZone);

  return `${begETAWindow} - ${endETAWindow}`;
};

const getDeliveryDateFromTimestamp = (deliveryStatus, timeWindow, actualArrivalTime, timeZone) => {
  if (getIsTruckArriving(deliveryStatus) && timeWindow?.from && timeWindow?.to) {
    return convertToTimezone((timeWindow?.from + timeWindow?.to) / 2, timeZone, DELIVERY_DATE_FORMAT);
  }
  if (
    (deliveryStatus === DELIVERY_STATUS_RECEIVED.ONSITE || deliveryStatus === DELIVERY_STATUS_RECEIVED.COMPLETED) &&
    actualArrivalTime
  ) {
    return convertToTimezone(actualArrivalTime, timeZone, DELIVERY_DATE_FORMAT);
  }
  return null;
};

const getMapBannerString = (deliveryStatus, timeWindow, actualArrivalTime, timeZone, t) => {
  let etaStatusText = null;
  let eta = null;
  let tEtaStatusText = null;
  if (getIsTruckArriving(deliveryStatus)) {
    etaStatusText = DELIVERY_TIME_WINDOW_PREFIX.ETA;
    tEtaStatusText = t('delivery.delivery.timeWindow.eta');
    eta = getETAString(timeWindow, timeZone);
  } else if (
    deliveryStatus === DELIVERY_STATUS_RECEIVED.ONSITE ||
    deliveryStatus === DELIVERY_STATUS_RECEIVED.COMPLETED
  ) {
    etaStatusText =
      deliveryStatus === DELIVERY_STATUS_RECEIVED.ONSITE
        ? DELIVERY_TIME_WINDOW_PREFIX.ARRIVED
        : DELIVERY_TIME_WINDOW_PREFIX.DELIVERED;
    tEtaStatusText =
      deliveryStatus === DELIVERY_STATUS_RECEIVED.ONSITE
        ? t('delivery.delivery.timeWindow.arrived')
        : t('delivery.delivery.timeWindow.delivered');
    eta = convertDeliveryTimeToTimestamp(actualArrivalTime, timeZone);
  }
  eta = `${eta} (${TIMEZONE_ABBREVIATIONS[timeZone]})`;

  const deliveryDateCalculated = getDeliveryDateFromTimestamp(deliveryStatus, timeWindow, actualArrivalTime, timeZone);
  return { etaStatusText, eta, deliveryDateCalculated, tEtaStatusText };
};

const getCustomNotificationSentence = (deliveryStatus, deliveryDate, eta, t) => {
  switch (deliveryStatus) {
    case DELIVERY_STATUS_RECEIVED.SCHEDULED:
      return t('delivery.delivery.notification.scheduled', { deliveryDate, eta });
    case DELIVERY_STATUS_RECEIVED.UPDATED_WINDOW:
      return t('delivery.delivery.notification.updated', { deliveryDate, eta });
    case DELIVERY_STATUS_RECEIVED.DELAYED:
      return t('delivery.delivery.notification.delayed', { deliveryDate, eta });
    case DELIVERY_STATUS_RECEIVED.ON_TIME:
      return t('delivery.delivery.notification.onTime', { deliveryDate, eta });
    case DELIVERY_STATUS_RECEIVED.ONSITE:
      return t('delivery.delivery.notification.onSite');
    case DELIVERY_STATUS_RECEIVED.COMPLETED:
      return t('delivery.delivery.notification.delivered', { deliveryDate, eta });
    default:
      return `${titleCase(deliveryStatus)} - ${deliveryDate} - ${eta}`;
  }
};

const mapLocationsToUIObject = location =>
  location ? { lat: location?.latitude, lng: location?.longitude } : { lat: null, lng: null };

const mapDeliveryStatusToUI = (status, t) => {
  let statusText = null;
  let deliveryClassName = null;
  let timeText = null;
  switch (status) {
    case DELIVERY_STATUS_RECEIVED.UPDATED_WINDOW:
      statusText = t('delivery.deliveryStatus.enRouteUpdated');
      deliveryClassName = DELIVERY_TAG_CLASS.UPDATED_WINDOW;
      timeText = t('delivery.deliveryTimeText.eta');
      break;
    case DELIVERY_STATUS_RECEIVED.DELAYED:
      statusText = t('delivery.deliveryStatus.delayed');
      deliveryClassName = DELIVERY_TAG_CLASS.DELAYED;
      timeText = t('delivery.deliveryTimeText.eta');
      break;
    case DELIVERY_STATUS_RECEIVED.ONSITE:
      statusText = t('delivery.deliveryStatus.arrived');
      deliveryClassName = DELIVERY_TAG_CLASS.ARRIVED;
      timeText = t('delivery.deliveryTimeText.arrived');
      break;
    case DELIVERY_STATUS_RECEIVED.COMPLETED:
      statusText = t('delivery.deliveryStatus.completed');
      deliveryClassName = DELIVERY_TAG_CLASS.COMPLETED;
      timeText = t('delivery.deliveryTimeText.delivered');
      break;
    case DELIVERY_STATUS_RECEIVED.SCHEDULED:
      statusText = t('delivery.deliveryStatus.scheduled');
      deliveryClassName = DELIVERY_TAG_CLASS.SCHEDULED;
      timeText = t('delivery.deliveryTimeText.eta');
      break;
    case DELIVERY_STATUS_RECEIVED.ON_TIME:
    default:
      statusText = t('delivery.deliveryStatus.enRoute');
      deliveryClassName = DELIVERY_TAG_CLASS.ON_TIME;
      timeText = t('delivery.deliveryTimeText.eta');
      break;
  }
  return { statusText: titleCase(statusText), deliveryClassName, timeText };
};

const mapDeliveryDetailsToUI = (delivery, customerDetails, customersWithDeliveries, t) => {
  if (delivery?.hasTodaysDelivery) {
    let customerName;
    let city;
    let addressLine2;
    let zipCode;
    let state;
    if (customerDetails?.customerInfo) {
      ({
        name: customerName,
        city,
        state,
        address_line_2: addressLine2,
        zip_code: zipCode
      } = customerDetails.customerInfo);
    } else if (customersWithDeliveries?.data?.length > 0) {
      // get customer name from bulk response if customer data is missing
      // TODO: remove if a bulk customer api is integrated
      const deliveryCustomer = customersWithDeliveries?.data?.find(
        c => delivery?.accountId === c?.customerId && delivery?.opcoId === c?.opcoId
      );
      ({ customerName } = deliveryCustomer || {});
    }

    const {
      delivery: { deliveryStatus, timeWindow, actualArrivalTime },
      timeZone,
      deliveryDate
    } = delivery || { delivery: {} };
    const customerAddress =
      `${addressLine2 && `${addressLine2} `}${city && `${city}, `}${state && `${state} `}${zipCode && `${zipCode}`}` ||
      '';

    const { eta, deliveryDateCalculated } = getMapBannerString(
      deliveryStatus,
      timeWindow,
      actualArrivalTime,
      timeZone,
      t
    );
    const { statusText: deliveryStatusString, deliveryClassName, timeText } = mapDeliveryStatusToUI(deliveryStatus, t);
    const deliveryNotificationText = getCustomNotificationSentence(deliveryStatus, deliveryDate, eta, t);
    const deliveryNotificationTime = delivery?.notificationDetails?.sentAt;

    return {
      customerName,
      customerAddress,
      eta,
      deliveryStatusString,
      deliveryClassName,
      timeText,
      deliveryDate: deliveryDateCalculated || deliveryDate,
      deliveryNotificationText,
      deliveryNotificationTime
    };
  }
  return {};
};

const mapLastUpdatedTimeToUI = lastUpdatedTime => {
  return moment(lastUpdatedTime || Date.now()).format('MM/DD/YY h:mm A');
};

function DeliveryDetailsPageContainer({
  deliveryDetails: { fetching, data: deliveryData },
  actions,
  customersWithDeliveries,
  extendedInvoicesDetails,
  extendedInvoicesFetching,
  invoicePdf,
  invoicePdfFetching
}) {
  const [lastUpdatedTime, setLastUpdatedTime] = useState(null);
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState(0);
  const [uiData, setUIData] = useState({
    mapMarkers: {
      truckLocation: { lat: null, lng: null },
      startingDepot: { lat: null, lng: null },
      stopLocation: { lat: null, lng: null }
    },
    mapBannerString: {
      eta: null,
      etaStatusText: null,
      tEtaStatusText: null
    },
    shouldDisplayTruckLocation: false,
    deliveryStatusDetails: {
      customerName: null,
      customerAddress: null,
      eta: null,
      deliveryStatusString: null,
      deliveryClassName: null,
      timeText: null,
      deliveryDate: null,
      deliveryNotificationText: null,
      deliveryNotificationTime: null
    },
    isLoggedIn: true
  });
  const [productInfo, setProductInfo] = useState(null);
  const [showProduct, setShowProduct] = useState(false);
  const [activeExpandedInvoices, setActiveExpandedInvoices] = useState([]);
  const [showPdf, setShowPdf] = useState(false); // todo: remove with relevant optimization
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState(null);
  const { t, i18n } = useTranslation();
  const globalContext = useContext(GlobalContext);

  const { language } = i18n;
  const { selectedCustomer } = globalContext;
  const { opco, customerId, customerDetails } = selectedCustomer || {};

  const { esyscoUserType } = globalContext.userDetails?.userDetails || {};

  const refreshDeliveryDetails = useCallback(() => {
    actions.loadDeliveryDetails(customerId, opco);
  }, [customerId, opco]);

  useEffect(() => {
    const isLoggedIn = Authenticator.isLoggedIn();

    let updatedUIData = uiData;

    if (isLoggedIn) {
      const mapBannerString = getMapBannerString(
        deliveryData?.delivery?.deliveryStatus,
        deliveryData?.delivery?.timeWindow,
        deliveryData?.delivery?.actualArrivalTime,
        deliveryData?.timeZone,
        t
      );

      const shouldDisplayTruckLocation =
        deliveryData?.delivery?.deliveryStatus === DELIVERY_STATUS_RECEIVED.ONSITE ||
        (deliveryData?.delivery?.deliveryStatus !== DELIVERY_STATUS_RECEIVED.SCHEDULED &&
          getIsTruckArriving(deliveryData?.delivery?.deliveryStatus));

      const mapMarkers = {
        truckLocation: shouldDisplayTruckLocation
          ? mapLocationsToUIObject(deliveryData?.truckLocation)
          : { lat: null, lng: null },
        startingDepot: mapLocationsToUIObject(deliveryData?.delivery?.startingDepot),
        stopLocation: mapLocationsToUIObject(deliveryData?.delivery?.stopLocation)
      };

      const deliveryStatusDetails = mapDeliveryDetailsToUI(deliveryData, customerDetails, customersWithDeliveries, t);

      updatedUIData = { mapMarkers, mapBannerString, shouldDisplayTruckLocation, deliveryStatusDetails };
    }

    setUIData({ ...updatedUIData, isLoggedIn });
  }, [deliveryData, customerDetails, language]);

  useEffect(() => {
    // TODO: Should the if block check
    // whether the current customer is the same as before?
    if (Authenticator.isLoggedIn()) {
      actions.loadDeliveryDetails(customerId, opco);
    }
  }, [opco, customerId]);

  useEffect(() => {
    if (deliveryData?.hasTodaysDelivery) {
      setTimeSinceLastUpdate(0);
      if (refreshTimeUpdate) {
        clearInterval(refreshTimeUpdate);
      }

      refreshTimeUpdate = setInterval(() => {
        setTimeSinceLastUpdate(prevTime => prevTime + 5);
      }, lastUpdateTimeInterval);

      if (mapRefreshInterval > 0) {
        if (mapUpdate) {
          clearInterval(mapUpdate);
        }
        if (deliveryData?.delivery?.deliveryStatus !== DELIVERY_STATUS_RECEIVED.COMPLETED) {
          mapUpdate = setInterval(refreshDeliveryDetails, mapRefreshInterval);
        }
      }
    }

    setLastUpdatedTime(Date.now());

    return () => {
      if (refreshTimeUpdate) {
        clearInterval(refreshTimeUpdate);
      }
    };
  }, [deliveryData]);

  useEffect(() => {
    if (!globalContext.isCustomerSelectorVisible) {
      globalContext.setIsCustomerSelectorVisible(true);
    }
  }, [globalContext]);

  useEffect(() => {
    if (Authenticator.isLoggedIn()) {
      setShowProduct(false);
      actions.loadExtendedInvoicesDetails(customerId, opco, '', language, esyscoUserType);
    }
  }, [actions, customerId, opco, language, esyscoUserType]);

  const showProductInfo = useCallback(
    (itemNumber, prodInfo) => {
      setProductInfo(prodInfo);
      setShowProduct(true);
    },
    [setProductInfo, setShowProduct]
  );

  const closeProductView = useCallback(() => {
    setShowProduct(false);
  }, [setShowProduct]);

  const onClickSelectedInvoices = useCallback(
    activeKeys => {
      setActiveExpandedInvoices(activeKeys);
    },
    [setActiveExpandedInvoices]
  );

  const onClickFetchInvoicePdf = useCallback(
    (e, invoiceNumber) => {
      e.preventDefault();
      setSelectedInvoiceNumber(invoiceNumber);
      actions.loadInvoicePdf(invoiceNumber, opco);
      setShowPdf(true);
    },
    [setSelectedInvoiceNumber, actions, setShowPdf, opco]
  );

  useEffect(() => {
    if (invoicePdf?.encodedUrl && invoicePdf?.isPdfUrl && showPdf) {
      downloadPDF(invoicePdf?.encodedUrl, opco, selectedInvoiceNumber);
    }
  }, [invoicePdf]);

  const hasNoTodaysDeliveries = deliveryData?.accountId === customerId && !deliveryData?.hasTodaysDelivery;
  const isInitialDeliveryDetailsCompleted = deliveryData?.accountId === customerId;
  const isInitialDeliveryDetailsFetchUnderway = deliveryData?.accountId !== customerId && fetching;
  const isDeliveryDetailsRefreshing = deliveryData?.accountId === customerId && fetching;

  const extendedInvoicesProps = {
    esyscoUserType,
    extendedInvoicesDetails,
    extendedInvoicesFetching,
    showProductInfo,
    closeProductView,
    onClickSelectedInvoices,
    productInfo,
    showProduct,
    activeExpandedInvoices,
    onClickFetchInvoicePdf,
    invoicePdfFetching
  };

  return (
    <DeliveryDetailsPageComponent
      hasNoTodaysDeliveries={hasNoTodaysDeliveries}
      isInitialDeliveryDetailsCompleted={isInitialDeliveryDetailsCompleted}
      isInitialDeliveryDetailsFetchUnderway={isInitialDeliveryDetailsFetchUnderway}
      isDeliveryDetailsRefreshing={isDeliveryDetailsRefreshing}
      shouldDisplayTruckLocation={uiData?.shouldDisplayTruckLocation}
      truckLocation={uiData?.mapMarkers?.truckLocation}
      startingDepot={uiData?.mapMarkers?.startingDepot}
      stopLocation={uiData?.mapMarkers?.stopLocation}
      mapETABanner={uiData?.mapBannerString}
      mapLanguage={language}
      deliveryStatusInformation={uiData?.deliveryStatusDetails}
      refreshDeliveryDetails={refreshDeliveryDetails}
      timeSinceLastUpdate={timeSinceLastUpdate}
      lastUpdatedTime={mapLastUpdatedTimeToUI(lastUpdatedTime)}
      extendedInvoicesProps={extendedInvoicesProps}
      isLoggedIn={uiData?.isLoggedIn}
    />
  );
}

const mapStateToProps = state => {
  return {
    deliveryDetails: state.deliveryDetails,
    customersWithDeliveries: state.customersWithDeliveries,
    extendedInvoicesDetails: state.extendedInvoices?.data,
    extendedInvoicesFetching: state.extendedInvoices?.fetching,
    invoicePdf: state.invoicePdf.data,
    invoicePdfFetching: state.invoicePdf.fetching
  };
};

const mapDispatchToProps = dispatch => {
  return { actions: bindActionCreators(deliveryActions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetailsPageContainer);
