export const DELIVERY_APP_ROUTES = {
  DASHBOARD: '/',
  DELIVERY_DETAILS: '/details',
  INVOICES: '/invoices',
  NOTIFICATIONS: '/notifications'
};

export const CATEGORY_ORDERING = [
  'outOfStock',
  'substitute',
  'Hlthcar/Hosplty',
  'Dairy Products',
  'Meats',
  'Seafood',
  'Poultry',
  'Frozen',
  'Canned And Dry',
  'Paper & Disp',
  'Chemical/Jantrl',
  'Supp & Equip',
  'Produce',
  'Dispenser Bevrg',
  'Uncategorized'
];

export const CATEGORY_DIVISION = {
  OUT_OF_STOCK: 'outOfStock',
  SUBSTITUE: 'substitute',
  OTHER: 'other' // all the other sysco categories will be relevant to same UI component
};

export const INVOICE_STATUS = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  OVERDUE: 'OVERDUE'
};

export const SPLIT_CODE_VALUE = 'S';

export const DELIVERY_STATUS_RECEIVED = {
  ON_TIME: 'ON_TIME',
  UPDATED_WINDOW: 'UPDATED_WINDOW',
  DELAYED: 'DELAYED',
  ONSITE: 'ONSITE',
  COMPLETED: 'DELIVERED',
  SCHEDULED: 'SCHEDULED'
};

export const DELIVERY_STATUS_DISPLAYED = {
  ON_TIME: 'EN ROUTE',
  UPDATED_WINDOW: 'EN ROUTE - UPDATED',
  DELAYED: 'DELAYED',
  ARRIVED: 'ARRIVED',
  COMPLETED: 'COMPLETED',
  UPDATED: 'EN ROUTE - UPDATED',
  SCHEDULED: 'SCHEDULED'
};

export const DELIVERY_TIME_TEXT = {
  ETA: 'ETA',
  DELIVERED: 'Delivered Time',
  ARRIVED: 'Arrived Time'
};

export const DELIVERY_TAG_CLASS = {
  ON_TIME: 'enroute',
  SCHEDULED: 'scheduled',
  UPDATED_WINDOW: 'enroute-updated',
  DELAYED: 'delayed',
  ARRIVED: 'arrived',
  COMPLETED: 'completed'
};

export const DELIVERY_TIME_WINDOW_PREFIX = {
  ETA: 'ETA',
  ARRIVED: 'Arrived',
  DELIVERED: 'Delivered',
  DELIVERY_DATE: 'Delivery Date'
};

export const TIMEZONE_ABBREVIATIONS = {
  'America/New_York': 'ET',
  'America/Chicago': 'CT',
  'America/Denver': 'MT',
  'America/Los_Angeles': 'PT',
  'America/Kentucky/Louisville': 'ET',
  'America/Indiana/Indianapolis': 'ET',
  'America/Detroit': 'ET',
  'America/Boise': 'MT',
  'America/Phoenix': 'MT',
  'America/Anchorage': 'AKT',
  'Pacific/Honolulu': 'HT',
  'America/Indiana/Knox': 'CT',
  'America/Indiana/Winamac': 'CT',
  'America/Indiana/Vevay': 'ET',
  'America/Indiana/Marengo': 'ET',
  'America/Indiana/Vincennes': 'ET',
  'America/Indiana/Tell_City': 'CT',
  'America/Indiana/Petersburg': 'ET',
  'America/Menominee': 'CT',
  'America/Shiprock': 'MT',
  'America/Nome': 'AKT',
  'America/Juneau': 'AKT',
  'America/Kentucky/Monticello': 'ET',
  'America/North_Dakota/Center': 'CT',
  'America/Yakutat': 'AKT',
  'US/Mountain': 'MT',
  'US/Alaska': 'AKT',
  'America/Toronto': 'ET',
  'America/Vancouver': 'PT',
  'America/Moncton': 'AT',
  'America/Winnipeg': 'CT',
  'America/Regina': 'CT',
  'America/Edmonton': 'MT',
  'America/St_Johns': 'NT'
};

export const LANGUAGES = {
  EN: 'en',
  FR: 'fr'
};

export const DELIVERY_DATE_FORMAT = 'MM/DD/YY';

export const NOTIFICATIONS_USER_DATA_LOCAL_STORAGE_PREFIX = 'SYSCO_PORTAL_SESSION_NOTIFICATIONS_USER_DATA';
