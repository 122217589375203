import React from 'react';
import { Tag, Col } from 'antd';
import moment from 'moment-timezone';
import { TIMEZONE_ABBREVIATIONS } from 'shared/constants';
import { useTranslation } from 'react-i18next';

export default function SelectCustomerButton({
  tileIndex,
  customerName,
  selectNewCustomer,
  address,
  deliveryDetails,
  customerId
}) {
  const { t } = useTranslation();
  const arrivalTime =
    deliveryDetails.actualArrivalTime &&
    moment
      .unix(deliveryDetails.actualArrivalTime)
      .tz(deliveryDetails.timeZone)
      .format('hh.mm A');

  const fromTime =
    deliveryDetails.timeWindow.from &&
    moment
      .unix(deliveryDetails.timeWindow.from)
      .tz(deliveryDetails.timeZone)
      .format('hh.mm A');

  const toTime =
    deliveryDetails.timeWindow.to &&
    moment
      .unix(deliveryDetails.timeWindow.to)
      .tz(deliveryDetails.timeZone)
      .format('hh.mm A');

  return (
    <div
      role="button"
      id={`delivery-box-${tileIndex}`}
      className="delivery-box"
      onClick={selectNewCustomer}
      tabIndex={-1}
      onKeyDown={selectNewCustomer}
    >
      <Col span={16}>
        <p id="delivery-box-customer-name" className="delivery-location">
          {customerName}
        </p>
        <p id="delivery-box-customer-id" className="delivery-location">
          {`${t('delivery.dashboard.customerId')}: ${customerId}`}
        </p>
        <p id="delivery-box-delivery-address" className="delivery-address">
          {address.address_line_2 && `${address.address_line_2} `}
          {address.address_line_3 && `${address.address_line_3} `}
          {address.city && `${address.city}, `}
          {address.state && `${address.state} `}
          {address.zip_code && `${address.zip_code}`}
        </p>
        <p id="delivery-date" className="delivery-date">
          {`${t('delivery.dashboard.deliveryDate')}: ${deliveryDetails.deliveryDate} `}
        </p>

        <p id="delivery-eta" className={`delivery-eta eta-${deliveryDetails.deliveryStatus.className}`}>
          {`${deliveryDetails.deliveryStatus.timeText}: `}
          {deliveryDetails.deliveryStatus.statusText === 'COMPLETED' ||
          deliveryDetails.deliveryStatus.statusText === 'ARRIVED'
            ? `${arrivalTime} (${TIMEZONE_ABBREVIATIONS[deliveryDetails.timeZone]})`
            : `${fromTime} - ${toTime} (${TIMEZONE_ABBREVIATIONS[deliveryDetails.timeZone]})`}
        </p>
      </Col>
      <Col span={8}>
        <Tag id="delivery-box-delivery-status-tag" className={`tag-${deliveryDetails.deliveryStatus.className}`}>
          {deliveryDetails.deliveryStatus.statusText}
        </Tag>
      </Col>
    </div>
  );
}
