import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { CATEGORY_DIVISION } from 'shared/constants';

const ItemPricePlaceHolder = ({ category, orderQty, originalOrderQty, unitOfMeasure, isPartial }) => {
  const { t } = useTranslation();

  if (category === CATEGORY_DIVISION.OUT_OF_STOCK) {
    return (
      <>
        <span className="icon-outofstock"></span>
        <p className="delivery-map-invoce-outofstock">{t(`delivery.invoices.itemCategory.${category}`)}</p>
      </>
    );
  }
  if (category === CATEGORY_DIVISION.SUBSTITUE) {
    return (
      <>
        <Row>
          <Col span={24} className="delivery-partial-stock-wraper">
            <h4 className="delivery-h1-price delivery-h1-price-substitute">{`${orderQty} ${unitOfMeasure}`}</h4>
          </Col>
          <Col span={24} className="invoice-partial-stock">
            <span>{t('delivery.invoices.itemCategory.substituteItem')}</span>
          </Col>
        </Row>
      </>
    );
  }
  if (isPartial) {
    return (
      <>
        <Row>
          <Col span={24} className="delivery-partial-stock-wraper">
            <h4 className="delivery-h1-price-partial delivery-orderqty">{`${orderQty}`}</h4>
            <h4 className="delivery-h1-price-partial">{`/ ${originalOrderQty} ${unitOfMeasure}`}</h4>
          </Col>
          <Col span={24} className="invoice-partial-stock">
            <span>{t('delivery.invoices.itemCategory.partial')}</span>
          </Col>
        </Row>
      </>
    );
  }
  return <h4 className="delivery-h1-price">{`${orderQty} ${unitOfMeasure}`}</h4>;
};

export default ItemPricePlaceHolder;
