import { combineReducers } from 'redux';
import initialState from 'store/initialState';

import {
  USER,
  EXTENDED_INVOICES,
  INVOICES_LIST,
  INVOICE,
  PRODUCT_INFO,
  INVOICE_PDF,
  CUSTOMERS_WITH_DELIVERIES,
  DELIVERY_DETAILS,
  NOTIFICATIONS,
  REFRESH_NOTIFICATIONS,
  REGISTER_USER_FOR_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS_REGISTRATION
} from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const user = createReducer(USER, initialState.user);
const extendedInvoices = createReducer(EXTENDED_INVOICES, initialState.extendedInvoices);
const invoicesList = createReducer(INVOICES_LIST, initialState.invoicesList);
const invoice = createReducer(INVOICE, initialState.invoice);
const product = createReducer(PRODUCT_INFO, initialState.product);
const invoicePdf = createReducer(INVOICE_PDF, initialState.invoicePdf);
const deliveryDetails = createReducer(DELIVERY_DETAILS, initialState.deliveryDetails);
const customersWithDeliveries = createReducer(CUSTOMERS_WITH_DELIVERIES, initialState.customersWithDeliveries);

const notifications = createReducer(NOTIFICATIONS, initialState.notifications);
const refreshCustomerNotifications = createReducer(REFRESH_NOTIFICATIONS, initialState.refreshCustomerNotifications);
const registerUserForNotifications = createReducer(
  REGISTER_USER_FOR_NOTIFICATIONS,
  initialState.registerUserForNotifications
);
const updateUserNotificationsRegistration = createReducer(
  UPDATE_USER_NOTIFICATIONS_REGISTRATION,
  initialState.updateUserNotificationsRegistration
);

export default combineReducers({
  user,
  extendedInvoices,
  invoicesList,
  invoice,
  product,
  invoicePdf,
  deliveryDetails,
  customersWithDeliveries,
  notifications,
  refreshCustomerNotifications,
  registerUserForNotifications,
  updateUserNotificationsRegistration
});
