const initialState = {
  user: {
    fetching: false,
    data: {
      userDetails: {
        email: [],
        opco: null,
        customerId: null,
        defaultCustomer: {
          opco: null,
          customerId: null,
          active: false
        },
        esyscoUserId: null,
        esyscoUserType: 0
      },
      customerList: [
        {
          customerId: null,
          opco: null,
          customerDetails: {
            opcoInfo: {
              id: null,
              name: null
            },
            customerInfo: {
              id: null,
              name: null,
              city: null,
              state: null,
              address_line_1: null,
              address_line_2: null,
              address_line_3: null,
              zip_code: null
            }
          }
        }
      ]
    },
    error: null
  },
  extendedInvoices: {
    fetching: false,
    data: {
      totalInvoices: null,
      totalItems: null,
      invoices: [
        {
          invoiceNumber: null,
          transDate: null,
          currTransTotAmt: null,
          isPdfUrl: false,
          orderNumber: [null],
          categories: {
            category: {
              totalAmount: null,
              lineItems: [
                {
                  itemNumber: null,
                  lineNumber: null,
                  itemDescription: null,
                  orderReasonCode: null,
                  splitCode: null,
                  currentSplitQty: null,
                  currentQty: null,
                  unitOfMeasure: null,
                  orderQty: null,
                  netWeight: null,
                  originalOrderQty: null,
                  originalShipQty: null,
                  itemAmount: null,
                  originalPrice: null,
                  lclCatgyCode: null,
                  categoryType: null,
                  isSubstitute: false,
                  isOutOfStock: false
                }
              ]
            }
          }
        }
      ]
    },
    error: null
  },
  invoicesList: {
    fetching: false,
    data: {
      totalItems: null,
      items: [
        {
          opcoNumber: null,
          customerNumber: null,
          invoiceDate: null,
          dueDate: null,
          invoiceNum: null,
          statusCode: null,
          statusMessage: null
        }
      ]
    },
    error: null
  },
  invoice: {
    fetching: false,
    data: {
      invoiceNumber: null,
      transDate: null,
      currTransTotAmt: null,
      isPdfUrl: false,
      orderNumber: [null],
      totalSplits: null,
      totalCases: null,
      substitutedItemsCount: null,
      outOfStockItemCount: null,
      categories: {
        category: {
          totalAmount: null,
          lineItems: [
            {
              itemNumber: null,
              lineNumber: null,
              itemDescription: null,
              orderReasonCode: null,
              splitCode: null,
              currentSplitQty: null,
              currentQty: null,
              unitOfMeasure: null,
              orderQty: null,
              netWeight: null,
              originalOrderQty: null,
              originalShipQty: null,
              itemAmount: null,
              originalPrice: null,
              lclCatgyCode: null,
              categoryType: null,
              isSubstitute: false,
              isOutOfStock: false
            }
          ]
        }
      }
    },
    error: null
  },
  product: {
    fetching: false,
    data: {},
    error: null
  },
  invoicePdf: {
    fetching: false,
    data: {
      isPdfUrl: false,
      encodedUrl: null
    },
    error: null
  },
  deliveryDetails: {
    fetching: true,
    data: {
      hasTodaysDelivery: null,
      deliveryDate: null,
      accountId: null,
      opcoId: '',
      timeZone: '',
      truckLocation: {
        latitude: null,
        longitude: null
      },
      delivery: {
        jobId: null,
        timeWindow: {
          from: null,
          to: null
        },
        deliveryStatus: null,
        actualArrivalTime: null,
        routeNumber: null,
        startingDepot: {
          latitude: null,
          longitude: null
        },
        stopLocation: {
          latitude: null,
          longitude: null
        }
      },
      notificationDetails: {
        hasDeliveryNotification: null,
        message: null,
        sentAt: null
      }
    }
  },
  customersWithDeliveries: {
    fetching: true,
    data: []
  },
  notifications: {
    fetching: true,
    data: { opcoId: null, customerId: null, notifications: [] }
  },
  refreshCustomerNotifications: { fetching: false, data: {}, error: null },
  registerUserForNotifications: {
    fetching: true,
    data: { notificationRegistryId: null, notificationUserId: null },
    error: null
  },
  updateUserNotificationsRegistration: { fetching: false, data: {}, error: null }
};

export default initialState;
