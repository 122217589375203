import axios from 'axios';
import Cookies from 'universal-cookie';
import { LANGUAGES } from 'shared/constants';

const baseURL = process.env.REACT_APP_BFF;

console.log('Delivery BFF Base Url', process.env.REACT_APP_BFF);

const api = axios.create({
  baseURL,
  timeout: 60000,
  withCredentials: true
});

function getHeaders(lang) {
  const headers = { 'Content-Type': 'application/json' };
  headers['Accept-Language'] = lang;
  if (process.env.REACT_APP_ENV === 'local') {
    const cookies = new Cookies();
    const userClaims = cookies.get('user-claims');
    if (userClaims) headers['x-amzn-oidc-data'] = userClaims;
  }

  return headers;
}

function postRequest(url, data, lang = LANGUAGES.EN) {
  return api.post(url, data, {
    headers: getHeaders(lang)
  });
}

async function getRequest(url, params = {}, lang = LANGUAGES.EN) {
  return api.get(url, {
    headers: getHeaders(lang),
    params
  });
}

function putRequest(url, data, lang = LANGUAGES.EN) {
  return api.put(url, data, {
    headers: getHeaders(lang)
  });
}

function deleteRequest(url) {
  return api.delete(url, {
    headers: getHeaders()
  });
}

export { postRequest, getRequest, putRequest, deleteRequest, getHeaders };
