import React from 'react';

import GeneralCategory from 'components/GeneralCategory';
import { CATEGORY_ORDERING } from 'shared/constants';

const CategoryOrdering = ({ esyscoUserType, categories, showProductInfo }) => {
  return (
    <>
      {CATEGORY_ORDERING.map((categoryName, categoryIndex) => {
        if (categories[categoryName]) {
          return (
            <>
              <GeneralCategory
                categoryIndex={categoryIndex}
                esyscoUserType={esyscoUserType}
                category={categoryName}
                categoryDetails={categories[categoryName]}
                showProductInfo={showProductInfo}
              />
            </>
          );
        }
        return null;
      })}
    </>
  );
};

export default CategoryOrdering;
