import React from 'react';
import { Col, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import AppLoader from 'components/AppLoader';
import ProductView from 'components/ProductView';
import CategoryOrdering from 'components/CategoryOrdering';

function SelectedInvoice({
  customerDetails,
  esyscoUserType,
  invoiceDetails,
  productDetails,
  invoicePdfFetching,
  onClickFetchInvoicePdf,
  showProductInfo,
  closeProductView,
  productInfo,
  showProduct
}) {
  const { t } = useTranslation();
  const {
    invoiceNumber,
    transDate,
    currTransTotAmt,
    categories,
    isPdfUrl,
    totalCases,
    totalSplits,
    statusCode
  } = invoiceDetails;

  return (
    <React.Fragment>
      {showProduct && productInfo?.productDetails?.productId ? (
        <ProductView
          esyscoUserType={esyscoUserType}
          productDetails={productDetails}
          productInfo={productInfo}
          closeProductView={closeProductView}
        />
      ) : (
        <React.Fragment>
          {invoicePdfFetching && <AppLoader show />}
          <Col span={10} id="delivery-invoice-selection" className="delivery-invoice-section">
            <div>
              <Col span={24}>
                <h1>{t('delivery.invoices.invoiceDetails')}</h1>
                <p className="invoice-title-selected">{`${customerDetails?.customerInfo?.name || ``}`}</p>
                <hr className="invoice-devider" />
              </Col>
              <Row className="selected-invoice-info">
                <Col span={12} className="invoice-number-wrap">
                  <h1 className="invoice-number">{`${invoiceNumber}`}</h1>
                  {esyscoUserType && isPdfUrl ? (
                    <Button type="link" onClick={onClickFetchInvoicePdf}>
                      <span className="icon-invoice"></span>
                    </Button>
                  ) : null}
                </Col>
                <Col span={12} className="invoice-item-right-wrap">
                  <h1 className="invoice-item-right">
                    {`${t('delivery.deliveries.totalItems')} `}
                    {totalCases ? `${totalCases} CS` : ''}
                    {totalCases && totalSplits ? ', ' : ''}
                    {totalSplits ? `${totalSplits} EA` : ''}
                  </h1>
                </Col>
                <Col span={18}>
                  <h1 className="invoice-paid-date">{`${transDate}`}</h1>
                  <h1 className="invoice-paid-lable">{esyscoUserType ? statusCode : ``}</h1>
                </Col>
                <Col span={6}>
                  <h1 className="invoice-paid-amount">{esyscoUserType ? `$${currTransTotAmt?.toFixed(2)}` : ``}</h1>
                </Col>
              </Row>
              <Col span={24}>
                <Row>
                  <CategoryOrdering
                    esyscoUserType={esyscoUserType}
                    categories={categories || {}}
                    showProductInfo={showProductInfo}
                  />
                </Row>
              </Col>
            </div>
          </Col>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default SelectedInvoice;
