import React, { useCallback, useContext, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as deliveryActions from 'actions';
import GlobalContext from 'context/GlobalContext';
import NotificationsPageComponent from './components/NotificationsPageComponent';

let lastRefreshedTimeUpdate = null;
const lastRefreshedTimeInterval = 5 * 1000; // in millis

function NotificationsPageContainer({
  actions,
  isNotificationsFetching,
  isNotificationsRefreshing,
  notifications,
  notificationUserId,
  notificationOpcoId,
  notificationCustomerId
}) {
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState(0);
  const globalContext = useContext(GlobalContext);

  const {
    selectedCustomer: {
      opco,
      customerId,
      customerDetails: { customerInfo: { name: selectedCustomerName } = {} } = {}
    } = {},
    isCustomerSelectorVisible,
    setIsCustomerSelectorVisible,
    userDetails: { userDetails: { esyscoUserId: userId } = {} } = {}
  } = globalContext;

  const showDeliveryNotificationsDetails = useCallback(
    (e, record) => {
      e.preventDefault();
      setSelectedDelivery(record);
    },
    [setSelectedDelivery]
  );

  const refreshNotifications = useCallback(() => {
    if (opco && customerId && notificationUserId) {
      actions.refreshCustomerNotifications(opco, customerId, userId, notificationUserId);
    }
  }, [opco, customerId, userId, notificationUserId, actions]);

  const onDeliverySelected = useCallback(
    record => {
      return {
        onClick: event => showDeliveryNotificationsDetails(event, record)
      };
    },
    [showDeliveryNotificationsDetails]
  );

  useEffect(() => {
    if (!isCustomerSelectorVisible) {
      setIsCustomerSelectorVisible(true);
    }
  }, [isCustomerSelectorVisible, setIsCustomerSelectorVisible]);

  useEffect(() => {
    if (opco && customerId && notificationUserId) {
      // refresh notifications when navigating between pages
      if (opco !== notificationOpcoId || customerId !== notificationCustomerId) {
        actions.loadCustomerNotifications(opco, customerId, userId, notificationUserId);
      } else {
        refreshNotifications();
      }
    }
  }, [opco, customerId, notificationUserId, userId, refreshNotifications, actions]);

  useEffect(() => {
    const isNotificationsLoading = isNotificationsFetching || isNotificationsRefreshing;
    const defaultSelectedDelivery = notifications?.length && !isNotificationsLoading ? notifications[0] : null;

    setSelectedDelivery(defaultSelectedDelivery);

    const clearLastRefreshedInterval = () => {
      if (lastRefreshedTimeUpdate) clearInterval(lastRefreshedTimeUpdate);
    };

    if (!isNotificationsLoading) {
      setTimeSinceLastUpdate(() => 0);
      lastRefreshedTimeUpdate = setInterval(() => setTimeSinceLastUpdate(prev => prev + 5), lastRefreshedTimeInterval);
    }

    return clearLastRefreshedInterval;
  }, [notifications, isNotificationsFetching, isNotificationsRefreshing]);

  const notificationsDataSourceUI = !isNotificationsFetching ? notifications : [];

  return (
    <NotificationsPageComponent
      isNotificationsFetching={isNotificationsFetching}
      notifications={notificationsDataSourceUI}
      selectedDelivery={selectedDelivery}
      onDeliverySelected={onDeliverySelected}
      timeSinceLastUpdate={timeSinceLastUpdate}
      refreshNotifications={refreshNotifications}
      isNotificationsRefreshing={isNotificationsRefreshing}
      customerName={selectedCustomerName}
    />
  );
}

function mapStateToProps(state) {
  return {
    isNotificationsFetching: state.notifications.fetching,
    isNotificationsRefreshing: state.refreshCustomerNotifications.fetching,
    notifications: state.notifications.data.notifications,
    notificationUserId: state.registerUserForNotifications.data.notificationUserId,
    notificationOpcoId: state.notifications.data.opcoId,
    notificationCustomerId: state.notifications.data.customerId
  };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(deliveryActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPageContainer);
