import React, { useCallback, useEffect, useState } from 'react';
import GoogleMapReact, { fitBounds } from 'google-map-react';

import destinationMarker from 'styles/images/map-marker.png';
import depotMarker from 'styles/images/truck-depot.png';
import truckMarker from 'styles/images/truck-marker.png';
import getMapBounds from 'util/getMapBounds';
import { useCustomMapControls } from 'hooks';
import RecenterTruckControl from './RecenterTruckControl';

const key = process.env.REACT_APP_GOOGLE_MAP_KEY;
const mapMarkerSize = '50px';

const defaultProps = {
  // Default center: Vancouver
  center: {
    lat: 49.2827,
    lng: -123.1207
  },
  zoom: 11
};

const defaultMapLanguage = 'en';
const defaultMapRegion = 'CA';
const truckLevelZoom = 15;

const recenterTruckControl = <RecenterTruckControl />;

const createMapOptions = () => ({
  styles: [
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    }
  ]
});

function TruckLocationComponent({
  shouldDisplayTruckLocation,
  truckLocation,
  startingDepot,
  stopLocation,
  mapETABanner,
  mapWidth,
  mapHeight,
  mapLanguage
}) {
  const [mapParams, setMapParams] = useState({ center: null, zoom: null });
  const [map, setMap] = useState(null);
  const [maps, setMapsObject] = useState(null);

  useEffect(() => {
    const mapSize = { height: mapHeight, width: mapWidth };
    const bounds = getMapBounds([truckLocation, startingDepot, stopLocation]);
    const centerAndZoom = fitBounds(bounds, mapSize);
    if (centerAndZoom.center.lat === 0 && centerAndZoom.center.lng === 180)
      centerAndZoom.center = { lat: 49.148831, lng: -121.941625 };

    // Force refresh map component on delivery details refresh if
    // the truck location has not changed
    const rand = Math.random() * 0.0001 + 0.0001;

    centerAndZoom.center.lat += rand;
    centerAndZoom.center.lng += rand;
    centerAndZoom.zoom += rand;

    setMapParams(centerAndZoom);
  }, [truckLocation, startingDepot, stopLocation]);

  const handleApiLoaded = useCallback(
    ({ map: mapObj, maps: mapsObj }) => {
      setMap(mapObj);
      setMapsObject(mapsObj);
    },
    [setMap, setMapsObject]
  );

  const recenterTruck = useCallback(() => {
    const { lat, lng } = truckLocation;
    const rand = Math.random() * 0.0001 + 0.0001;

    setMapParams({ center: { lat: lat + rand, lng: lng + rand }, zoom: truckLevelZoom - rand });
  }, [setMapParams, truckLocation]);

  useCustomMapControls(
    map,
    maps?.ControlPosition?.RIGHT_CENTER,
    recenterTruckControl,
    recenterTruck,
    shouldDisplayTruckLocation
  );

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key, region: defaultMapRegion, language: mapLanguage || defaultMapLanguage }} // Current (04/21) Google Maps JS API does not support dynamic language changes
      options={createMapOptions}
      defaultCenter={defaultProps?.center}
      defaultZoom={defaultProps?.zoom}
      center={mapParams?.center}
      zoom={mapParams?.zoom - 0.5}
      onGoogleApiLoaded={handleApiLoaded}
    >
      <div className="delivery-map-eta-banner-container" lat={stopLocation?.lat} lng={stopLocation?.lng} hover>
        <div className="delivery-map-eta-banner">
          <div>{mapETABanner?.tEtaStatusText}</div>
          <div>{mapETABanner?.eta}</div>
        </div>
      </div>
      <img
        className="delivery-map-location-marker"
        src={destinationMarker}
        lat={stopLocation?.lat}
        lng={stopLocation?.lng}
        height={mapMarkerSize}
        alt="destination marker"
      />
      <img
        className="delivery-map-location-marker"
        src={depotMarker}
        lat={startingDepot?.lat}
        lng={startingDepot?.lng}
        height={mapMarkerSize}
        alt="warehouse marker"
      />
      {truckLocation?.lat && truckLocation?.lng && (
        <img
          className="delivery-map-location-marker"
          src={truckMarker}
          lat={truckLocation?.lat}
          lng={truckLocation?.lng}
          height={mapMarkerSize}
          alt="truck marker"
        />
      )}
    </GoogleMapReact>
  );
}

export default TruckLocationComponent;
