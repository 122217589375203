import { action } from 'reduxHelpers';
import {
  USER,
  EXTENDED_INVOICES,
  INVOICES_LIST,
  INVOICE,
  INVOICE_DELETE,
  PRODUCT_INFO,
  INVOICE_PDF,
  CUSTOMERS_WITH_DELIVERIES,
  DELIVERY_DETAILS,
  NOTIFICATIONS,
  REGISTER_USER_FOR_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS_REGISTRATION,
  REFRESH_NOTIFICATIONS
} from './actionTypes';

const loadExtendedInvoicesDetails = (customerId, opcoId, routeNumber, lang, esyscoUserType) =>
  action(EXTENDED_INVOICES.REQUEST, { customerId, opcoId, routeNumber, lang, esyscoUserType });

const loadInvoicesList = (customerId, opcoId, esyscoUserType) => {
  return action(INVOICES_LIST.REQUEST, { customerId, opcoId, esyscoUserType });
};

const loadInvoice = (customerId, opcoId, invoiceNumber, lang, esyscoUserType) =>
  action(INVOICE.REQUEST, { customerId, opcoId, invoiceNumber, lang, esyscoUserType });

const deleteInvoice = () => action(INVOICE_DELETE.REQUEST);

const loadProductDetails = (customerId, opcoId, supcNumber) =>
  action(PRODUCT_INFO.REQUEST, { customerId, opcoId, supcNumber });

const loadInvoicePdf = (invoiceNumber, opcoId) => action(INVOICE_PDF.REQUEST, { invoiceNumber, opcoId });

const loadUserDetails = userId => action(USER.REQUEST, { userId });

const loadDeliveryDetails = (customerId, opco) => action(DELIVERY_DETAILS.REQUEST, { customerId, opco });
const loadCustomersWithDeliveries = user => action(CUSTOMERS_WITH_DELIVERIES.REQUEST, { user });

const loadCustomerNotifications = (opcoId, customerId, userId, notificationUserId) =>
  action(NOTIFICATIONS.REQUEST, {
    opcoId,
    customerId,
    userId,
    notificationUserId
  });
const refreshCustomerNotifications = (opcoId, customerId, userId, notificationUserId) =>
  action(REFRESH_NOTIFICATIONS.REQUEST, {
    opcoId,
    customerId,
    userId,
    notificationUserId
  });

const registerUserForNotifications = (userId, language) =>
  action(REGISTER_USER_FOR_NOTIFICATIONS.REQUEST, { userId, language });
const updateUserNotificationsRegistration = (language, userId, notificationUserId) =>
  action(UPDATE_USER_NOTIFICATIONS_REGISTRATION.REQUEST, { language, userId, notificationUserId });

export {
  loadUserDetails,
  loadExtendedInvoicesDetails,
  loadInvoicesList,
  loadInvoice,
  deleteInvoice,
  loadProductDetails,
  loadInvoicePdf,
  loadDeliveryDetails,
  loadCustomersWithDeliveries,
  loadCustomerNotifications,
  refreshCustomerNotifications,
  registerUserForNotifications,
  updateUserNotificationsRegistration
};
