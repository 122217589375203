import { NOTIFICATIONS_USER_DATA_LOCAL_STORAGE_PREFIX } from 'shared/constants';

export const getStoredNotificationUserData = userId => {
  const userDataStr = localStorage.getItem(`${NOTIFICATIONS_USER_DATA_LOCAL_STORAGE_PREFIX}_${userId}`);
  let notificationUserId = null;
  let notificationRegistryId = null;
  let lastUpdatedLanguage = null;

  if (userDataStr) {
    try {
      ({ notificationUserId = null, notificationRegistryId = null, lastUpdatedLanguage = null } = JSON.parse(
        userDataStr
      ));
    } catch (error) {
      console.log(error);
    }
  }

  return { notificationUserId, notificationRegistryId, lastUpdatedLanguage };
};

export const storeNotificationUserDataInLocalStorage = (userId, userData) => {
  localStorage.setItem(`${NOTIFICATIONS_USER_DATA_LOCAL_STORAGE_PREFIX}_${userId}`, JSON.stringify(userData));
};

export const removeStoredNotificationUserData = userId => {
  localStorage.removeItem(`${NOTIFICATIONS_USER_DATA_LOCAL_STORAGE_PREFIX}_${userId}`);
};
