import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const EXTENDED_INVOICES = createRequestTypes('EXTENDED_INVOICES');
const INVOICES_LIST = createRequestTypes('INVOICES_LIST');
const INVOICE = createRequestTypes('INVOICE');
const INVOICE_DELETE = createRequestTypes('INVOICE_DELETE');
const INVOICE_PDF = createRequestTypes('INVOICE_PDF');
const PRODUCT_INFO = createRequestTypes('PRODUCT_INFO');
const DELIVERY_DETAILS = createRequestTypes('DELIVERY_DETAILS');
const CUSTOMERS_WITH_DELIVERIES = createRequestTypes('CUSTOMERS_WITH_DELIVERIES');
const NOTIFICATIONS = createRequestTypes('NOTIFICATIONS');
const REFRESH_NOTIFICATIONS = createRequestTypes('REFRESH_NOTIFICATIONS');
const REGISTER_USER_FOR_NOTIFICATIONS = createRequestTypes('REGISTER_USER_FOR_NOTIFICATIONS');
const UPDATE_USER_NOTIFICATIONS_REGISTRATION = createRequestTypes('UPDATE_NOTIFICATION_USER_REGISTRATION');

export {
  USER,
  EXTENDED_INVOICES,
  INVOICES_LIST,
  INVOICE,
  INVOICE_DELETE,
  PRODUCT_INFO,
  INVOICE_PDF,
  DELIVERY_DETAILS,
  CUSTOMERS_WITH_DELIVERIES,
  NOTIFICATIONS,
  REFRESH_NOTIFICATIONS,
  REGISTER_USER_FOR_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS_REGISTRATION
};
