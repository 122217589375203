import React, { useContext, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as deliveryActions from 'actions';
import { DELIVERY_APP_ROUTES } from 'shared/constants';
import GlobalContext from 'context/GlobalContext';
import NavigationPanel from 'components/NavigationPanel';
import DashboardPageContainer from 'pages/DashboardPage/DashboardPageContainer';
import NotificationsPageContainer from 'pages/NotificationsPage/NotificationsPageContainer';
import InvoicesDetailsContainer from './pages/InvoicesDetailsPage/InvoicesDetailsPageContainer';
import DeliveryDetailsPageContainer from './pages/DeliveryDetailsPage/DeliveryDetailsPageContainer';
import styles from './styles/CostingApp.scss';

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ actions, notificationUserId }) {
  setAppHeaderToAppMode();

  const { userDetails: { userDetails: { esyscoUserId: userId } = {} } = {}, language } = useContext(GlobalContext);

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    // It is assumed that language is already set when application is loaded
    actions.registerUserForNotifications(userId, language);
  }, []);

  useEffect(() => {
    if (language && notificationUserId)
      actions.updateUserNotificationsRegistration(language, userId, notificationUserId);
  }, [language, userId, notificationUserId]);

  return (
    <div className="wrapper delivery-wrapper">
      <Router basename="suite/delivery">
        <NavigationPanel />
        <Switch>
          <Route exact path={DELIVERY_APP_ROUTES.DASHBOARD} component={DashboardPageContainer} />
          <Route path={DELIVERY_APP_ROUTES.DELIVERY_DETAILS} component={DeliveryDetailsPageContainer} />
          <Route path={DELIVERY_APP_ROUTES.INVOICES} component={InvoicesDetailsContainer} />
          <Route path={DELIVERY_APP_ROUTES.NOTIFICATIONS} component={NotificationsPageContainer} />
        </Switch>
      </Router>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    notificationUserId: state.registerUserForNotifications.data.notificationUserId
  };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(deliveryActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
