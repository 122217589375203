import React, { useCallback } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import momentTz from 'moment-timezone';
import { DELIVERY_DATE_FORMAT } from 'shared/constants';

function DeliveryListComponent({ notifications, onDeliverySelected, isNotificationsFetching, selectedDeliveryJobId }) {
  const { t } = useTranslation();

  const setRowClassName = useCallback(
    record => {
      if (record?.jobId === selectedDeliveryJobId) return 'invoice-table-tr-active';
      return '';
    },
    [selectedDeliveryJobId]
  );

  const columns = [
    {
      title: `${t('delivery.notifications.deliveryDate')}`,
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: (a, b) => {
        return (
          momentTz(a.deliveryDate, DELIVERY_DATE_FORMAT).unix() - momentTz(b.deliveryDate, DELIVERY_DATE_FORMAT).unix()
        );
      },
      render: text => <span className="invoice-table-title">{text}</span>
    },
    {
      title: `${t('delivery.notifications.latestMessage')}`,
      dataIndex: 'latestNotification',
      key: 'latestNotificationMessage',
      render: notification => notification?.message
    },
    {
      title: `${t('delivery.notifications.sentAt')}`,
      dataIndex: 'latestNotification',
      key: 'latestNotificationSentAt',
      render: notification => notification?.sentAt
    }
  ];
  return (
    <Table
      id="notifications-table"
      pagination={{ pageSize: 19 }}
      columns={columns}
      dataSource={notifications}
      onRow={onDeliverySelected}
      rowClassName={setRowClassName}
      loading={isNotificationsFetching}
      rowKey="jobId"
    />
  );
}

export default DeliveryListComponent;
