import { isNumber } from 'lodash';

const getMapBounds = markers => {
  const latitudes = [];
  const longitudes = [];

  markers.forEach(marker => {
    if (isNumber(marker?.lat) && isNumber(marker?.lng)) {
      latitudes.push(marker.lat);
      longitudes.push(marker.lng);
    }
  });

  let bounds = { ne: { lat: 0, lng: 0 }, sw: { lat: 0, lng: 0 } };

  if (latitudes.length !== 0 || longitudes.length !== 0) {
    bounds = {
      ne: { lat: Math.max(...latitudes), lng: Math.max(...longitudes) },
      sw: { lat: Math.min(...latitudes), lng: Math.min(...longitudes) }
    };
  }

  return bounds;
};

export default getMapBounds;
