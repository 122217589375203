import React from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';

export default function PartialQuantityView({ orderQty, originalOrderQty, unitOfMeasure }) {
  const { t } = useTranslation();
  return (
    <>
      <Col span={24} className="delivery-partial-stock-detailview-wraper">
        <h1 className="delivery-h1-price-partial-detailview delivery-orderqty">{`${orderQty}`}</h1>
        <h1 className="delivery-h1-price-partial-detailview">{`/ ${originalOrderQty} ${unitOfMeasure}`}</h1>
      </Col>
      <Col span={24} className="invoice-partial-stock-detailview">
        <span className="detailview-text">{t('delivery.invoices.itemCategory.partial')}</span>
      </Col>
    </>
  );
}
