import momentTz from 'moment-timezone';

const convertToTimezone = (unixTime, timeZone, dateFormat) => {
  return momentTz
    .unix(unixTime)
    .tz(timeZone)
    .format(dateFormat);
};

export default convertToTimezone;
