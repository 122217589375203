import React, { useEffect } from 'react';
import { render } from 'react-dom';

const onKeyDown = () => {};

const useMapControl = (map, controlPosition, children, onClick, shouldRenderChildren) => {
  useEffect(() => {
    if (map && controlPosition && shouldRenderChildren) {
      render(
        <div
          ref={el => map?.controls[controlPosition]?.push(el)}
          onClick={onClick}
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDown}
          className="delivery-map-control"
        >
          {children}
        </div>,
        document.createElement('div')
      );
    }
    return () => {
      if (map && controlPosition) {
        map.controls[controlPosition].clear();
      }
    };
  }, [map, controlPosition, children, onClick, shouldRenderChildren]);
};

export default useMapControl;
