import React from 'react';

import RecenterIcon from 'styles/images/my-location.png';

// TODO: Add an SVG with hove effects
function RecenterTruckControl() {
  return <img src={RecenterIcon} className="delivery-recenter-truck-control" alt="recenter icon" />;
}

export default RecenterTruckControl;
