import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';

function CustomerDeliveryStatus({
  customerName,
  customerAddress,
  eta,
  deliveryStatusString,
  lastUpdatedTime,
  deliveryClassName,
  timeText,
  deliveryNotificationText,
  deliveryNotificationTime
}) {
  const [progressBarClassname, setProgressBarClassname] = useState('');
  const [truckPositionClassname, setTruckPositionClassname] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    setProgressBarClassname(`progress progress-${deliveryClassName}`);
    setTruckPositionClassname(`truck truck-posisition truck-posisition-${deliveryClassName}`);
  }, [deliveryClassName]);

  return (
    <Col span={24} id="selected-delivery-info" className="delivery-info">
      <Col span={16}>
        {' '}
        <p className="delivery-title">{`${customerName}`}</p>
        <p>{customerAddress}</p>
        <p>{`${t('delivery.deliveries.deliveryStatus')}: ${deliveryStatusString}`}</p>
      </Col>
      <Col span={8}>
        <p className="delivery-estimation-lable">{timeText}</p>
        <h1 className="delivery-time">{eta}</h1>
      </Col>
      <Col span={24} className="progress-bar">
        <div className="container-p">
          <div className={progressBarClassname}>
            <span className="delivery-location-start"></span>
            <span className={truckPositionClassname}></span>
            <span className="invoice-delivery-location"></span>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <p className="delivery-progress-lable">{t('delivery.delivery.deliveryProgress')}</p>
        <p className="delivery-estimation-time">{deliveryNotificationText}</p>
        <p className="">{deliveryNotificationTime || lastUpdatedTime}</p>
      </Col>
    </Col>
  );
}

export default CustomerDeliveryStatus;
