import { all, put, call, takeLatest, takeEvery } from 'redux-saga/effects';

import { action } from 'reduxHelpers';
import { postRequest, getRequest, putRequest } from '_http';
import {
  USER,
  EXTENDED_INVOICES,
  INVOICES_LIST,
  INVOICE,
  INVOICE_DELETE,
  PRODUCT_INFO,
  INVOICE_PDF,
  CUSTOMERS_WITH_DELIVERIES,
  DELIVERY_DETAILS,
  NOTIFICATIONS,
  REGISTER_USER_FOR_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS_REGISTRATION,
  REFRESH_NOTIFICATIONS
} from 'actions/actionTypes';
import i18n from '_i18n';
import initialState from 'store/initialState';
import openNotification from 'components/openNotification';
import * as Authenticator from 'Authenticator';
import {
  getStoredNotificationUserData,
  removeStoredNotificationUserData,
  storeNotificationUserDataInLocalStorage
} from 'util/NotificationLocalStorageUtil';

const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

const showErrorNotification = (error, message) => {
  return action(SHOW_NOTIFICATION, {
    description: error?.langTranslation
      ? `${i18n.t(`delivery.errorNotification.description.${error?.langTranslation}`)}`
      : `${i18n.t(`delivery.errorNotification.description.tryAgain`)}`,
    className: 'error',
    message: i18n.t(`delivery.errorNotification.message.${message}`)
  });
};

function* checkAndHandleUserSessionErrors(error) {
  let handled = false;
  if (error?.response?.status === 401) {
    yield put(showErrorNotification({ langTranslation: `sessionExpired` }, `sessionExpired`));
    Authenticator.logout();
    handled = true;
  }
  return handled;
}

function* loadUserAsync({ user }) {
  try {
    yield put({ type: USER.SUCCESS, payload: user });
  } catch (error) {
    yield put({ type: CUSTOMERS_WITH_DELIVERIES.FAILURE, payload: error.message });
    yield put(showErrorNotification(error?.response?.data, `userDetails`));
  }
}

function* loadUsersWithDeliveries({ user }) {
  try {
    if (user) {
      const response = yield call(postRequest, `/delivery-details/customers/all`, user);
      yield put({ type: CUSTOMERS_WITH_DELIVERIES.SUCCESS, payload: response.data });
    } else {
      yield put({ type: CUSTOMERS_WITH_DELIVERIES.FAILURE });
      yield put(showErrorNotification({ langTranslation: `userDetails` }, `deliveryDetails`));
    }
  } catch (error) {
    const handled = yield checkAndHandleUserSessionErrors(error);
    if (!handled) {
      yield put({ type: CUSTOMERS_WITH_DELIVERIES.FAILURE, payload: error.message });
      yield put(showErrorNotification(error?.response?.data, `deliveryDetails`));
    }
  }
}

function* loadDeliveryDetailsAsync({ customerId, opco }) {
  try {
    if (customerId && opco) {
      const response = yield call(getRequest, `/delivery-details/customers/${customerId}/opcos/${opco}`);
      yield put({ type: DELIVERY_DETAILS.SUCCESS, payload: response.data });
    } else {
      yield put({ type: DELIVERY_DETAILS.FAILURE });
      yield put(showErrorNotification({ langTranslation: `customerDetails` }, `deliveryDetails`));
    }
  } catch (error) {
    const handled = yield checkAndHandleUserSessionErrors(error);
    if (!handled) {
      yield put({ type: DELIVERY_DETAILS.FAILURE, payload: error.message });
    }
  }
}

function* loadExtendedInvoicesAsync({ customerId, opcoId, lang, esyscoUserType }) {
  try {
    // todo: if routeNumber essential, routeNumber needs to be included in if clause
    if (customerId && opcoId) {
      // todo: remove the following comments after DEV and QA testing
      //   let routeDetails;

      //   if (!routeNumber) {
      //     routeDetails = yield call(getRequest, `/delivery-details/customers/${customerId}/opcos/${opcoId}`);

      //     routeNumber = routeDetails.data.delivery.routeNumber;
      //   }

      // const response = yield call(
      //   getRequest,
      //   `/invoices/mapped/customers/${customerId}/opcos/${opcoId}?routeNumber=${routeNumber}`
      // );

      const response = yield call(
        getRequest,
        `/invoices/mapped/customers/${customerId}/opcos/${opcoId}`,
        { esyscoUserType },
        lang
      );

      yield put({ type: EXTENDED_INVOICES.SUCCESS, payload: response.data });
    } else {
      yield put({ type: EXTENDED_INVOICES.FAILURE });
      yield put(showErrorNotification({ langTranslation: `customerDetails` }, `extendedInvoiceDetails`));
    }
  } catch (error) {
    const handled = yield checkAndHandleUserSessionErrors(error);
    if (!handled) {
      yield put({ type: EXTENDED_INVOICES.FAILURE, payload: error.message });
      yield put(showErrorNotification(error?.response?.data, `extendedInvoiceDetails`));
    }
  }
}

function* loadInvoicesListAsync({ customerId, opcoId, esyscoUserType }) {
  try {
    if (customerId && opcoId) {
      const response = yield call(getRequest, `/invoices/details/customers/${customerId}/opcos/${opcoId}/all`, {
        esyscoUserType
      });

      yield put({ type: INVOICES_LIST.SUCCESS, payload: response.data });
    } else {
      yield put({ type: INVOICES_LIST.FAILURE });
      yield put(showErrorNotification({ langTranslation: `customerDetails` }, `listOfInvoices`));
    }
  } catch (error) {
    const handled = yield checkAndHandleUserSessionErrors(error);
    if (!handled) {
      yield put({ type: INVOICES_LIST.FAILURE, payload: error.message });
      yield put(showErrorNotification(error?.response?.data, `listOfInvoices`));
    }
  }
}

function* loadInvoiceAsync({ customerId, opcoId, invoiceNumber, lang, esyscoUserType }) {
  try {
    if (customerId && opcoId) {
      const response = yield call(
        getRequest,
        `/invoices/mapped/customers/${customerId}/opcos/${opcoId}/invoice/${invoiceNumber}`,
        { esyscoUserType },
        lang
      );

      yield put({ type: INVOICE.SUCCESS, payload: response.data });
    }
  } catch (error) {
    const handled = yield checkAndHandleUserSessionErrors(error);
    if (!handled) {
      yield put({ type: INVOICE.FAILURE, payload: error.message });
      yield put(showErrorNotification(error?.response?.data, `invoiceDetails`));
    }
  }
}

function* deleteInvoiceAsync() {
  try {
    yield put({ type: INVOICE.SUCCESS, payload: initialState.invoice.data });
  } catch (error) {
    yield put({ type: INVOICE_DELETE.FAILURE, payload: error.message });
    yield put(showErrorNotification(error?.response?.data, `deleteInvoice`));
  }
}

function* loadProductAsync({ customerId, opcoId, supcNumber }) {
  try {
    if (customerId && opcoId) {
      const response = yield call(
        getRequest,
        `/products/details/customers/${customerId}/opcos/${opcoId}?supc=${supcNumber}`
      );

      yield put({ type: PRODUCT_INFO.SUCCESS, payload: response.data });
    }
  } catch (error) {
    const handled = yield checkAndHandleUserSessionErrors(error);
    if (!handled) {
      yield put({ type: PRODUCT_INFO.FAILURE, payload: error.message });
      yield put(showErrorNotification(error?.response?.data, `productDetails`));
    }
  }
}

function* loadInvoicePdfAsync({ invoiceNumber, opcoId }) {
  try {
    if (invoiceNumber && opcoId) {
      const response = yield call(getRequest, `/invoices/${invoiceNumber}/opco/${opcoId}/download`);

      yield put({ type: INVOICE_PDF.SUCCESS, payload: response.data });
    }
  } catch (error) {
    const handled = yield checkAndHandleUserSessionErrors(error);
    if (!handled) {
      yield put({ type: INVOICE_PDF.FAILURE, payload: error.message });
      yield put(showErrorNotification(error?.response?.data, `invoicePDFFetch`));
    }
  }
}

function* registerUserForNotificationsAsync({ userId, language }) {
  try {
    let userData = getStoredNotificationUserData(userId);

    if (!userData.notificationUserId) {
      const { data: { notificationUserId = null, notificationRegistryId = null } = {} } = yield call(
        postRequest,
        `/notifications/register`,
        null,
        language
      );
      userData = { notificationUserId, notificationRegistryId, lastUpdatedLanguage: language };

      storeNotificationUserDataInLocalStorage(userId, userData);
    }
    yield put({ type: REGISTER_USER_FOR_NOTIFICATIONS.SUCCESS, payload: userData });
  } catch (error) {
    const handled = yield checkAndHandleUserSessionErrors(error);
    if (!handled) {
      yield put({ type: REGISTER_USER_FOR_NOTIFICATIONS.FAILURE, payload: error.message });
      // Should we show an error message?
      // yield put(showErrorNotification({ langTranslation: `notifications` }, `notifications`));
    }
  }
}

function* updateUserNotificationRegistrationAsync({ language, userId, notificationUserId }) {
  if (language && notificationUserId) {
    try {
      const userData = getStoredNotificationUserData(userId);

      let updatedDetails;

      if (userData.lastUpdatedLanguage !== language) {
        const response = yield call(putRequest, `/notifications/update/user/${notificationUserId}`, null, language);
        updatedDetails = response.data;
        storeNotificationUserDataInLocalStorage(userId, { ...userData, lastUpdatedLanguage: language });
      } else {
        updatedDetails = {
          message: 'Language has been already updated',
          data: {
            userId: notificationUserId
          }
        };
      }

      yield put({ type: UPDATE_USER_NOTIFICATIONS_REGISTRATION.SUCCESS, payload: updatedDetails });
    } catch (error) {
      const handled = yield checkAndHandleUserSessionErrors(error);
      if (!handled) {
        yield put({ type: UPDATE_USER_NOTIFICATIONS_REGISTRATION.FAILURE, payload: error.message });
        // yield put(showErrorNotification({ langTranslation: `notifications` }, `notifications`));
      }
    }
  }
}

function* loadCustomerNotificationsAsync({ opcoId, customerId, userId, notificationUserId }) {
  if (opcoId && customerId && userId && notificationUserId) {
    try {
      const response = yield call(
        getRequest,
        `/notifications/customer/${customerId}/opco/${opcoId}/user/${notificationUserId}/all`
      );
      const payload = { opcoId, customerId, notifications: response.data };

      yield put({ type: NOTIFICATIONS.SUCCESS, payload });

      /* Note: It is assumed that an empty notification array is resulted by a corrupted notification user id.
         Clear the cached user data in such case */
      if (!payload.notifications?.length) {
        removeStoredNotificationUserData(userId);
      }
    } catch (error) {
      const handled = yield checkAndHandleUserSessionErrors(error);
      if (!handled) {
        yield put({ type: NOTIFICATIONS.FAILURE, payload: error.message });
        yield put(showErrorNotification({ langTranslation: `notifications` }, `notifications`));
      }
    }
  }
}

function* refreshCustomerNotificationsAsync({ opcoId, customerId, userId, notificationUserId }) {
  if (opcoId && customerId && userId && notificationUserId) {
    try {
      const response = yield call(
        getRequest,
        `/notifications/customer/${customerId}/opco/${opcoId}/user/${notificationUserId}/all`
      );

      yield put({ type: NOTIFICATIONS.SUCCESS, payload: { opcoId, customerId, notifications: response.data } });
      yield put({ type: REFRESH_NOTIFICATIONS.SUCCESS, payload: { opcoId, customerId, notifications: response.data } });
    } catch (error) {
      const handled = yield checkAndHandleUserSessionErrors(error);
      if (!handled) {
        if (error?.response?.status === 404) {
          removeStoredNotificationUserData(userId);
        }
        yield put({ type: REFRESH_NOTIFICATIONS.FAILURE, payload: error.message });
        yield put(showErrorNotification({ langTranslation: `notifications` }, `notifications`));
      }
    }
  }
}

function* showNotificationAsync(notificationAction) {
  const { message, description, className, isClosable } = notificationAction || {};
  yield openNotification({ message, description, className, isClosable });
}

function* watchLoadUser() {
  yield takeLatest(USER.REQUEST, loadUserAsync);
}

function* watchLoadCustomersWithDeliveries() {
  yield takeLatest(CUSTOMERS_WITH_DELIVERIES.REQUEST, loadUsersWithDeliveries);
}

function* watchLoadDeliveryDetails() {
  yield takeLatest(DELIVERY_DETAILS.REQUEST, loadDeliveryDetailsAsync);
}

function* watchLoadExtendedInvoices() {
  yield takeLatest(EXTENDED_INVOICES.REQUEST, loadExtendedInvoicesAsync);
}

function* watchLoadInvoicesList() {
  yield takeLatest(INVOICES_LIST.REQUEST, loadInvoicesListAsync);
}

function* watchLoadInvoice() {
  yield takeLatest(INVOICE.REQUEST, loadInvoiceAsync);
}

function* watchDeleteInvoice() {
  yield takeLatest(INVOICE_DELETE.REQUEST, deleteInvoiceAsync);
}

function* watchLoadProduct() {
  yield takeLatest(PRODUCT_INFO.REQUEST, loadProductAsync);
}

function* watchLoadInvoicePdf() {
  yield takeLatest(INVOICE_PDF.REQUEST, loadInvoicePdfAsync);
}

function* watchLoadCustomerNotifications() {
  yield takeLatest(NOTIFICATIONS.REQUEST, loadCustomerNotificationsAsync);
}

function* watchRefreshCustomerNotificationsAsync() {
  yield takeLatest(REFRESH_NOTIFICATIONS.REQUEST, refreshCustomerNotificationsAsync);
}

function* watchRegisterUserForNotifications() {
  yield takeLatest(REGISTER_USER_FOR_NOTIFICATIONS.REQUEST, registerUserForNotificationsAsync);
}

function* watchUpdateUserNotificationRegistration() {
  yield takeLatest(UPDATE_USER_NOTIFICATIONS_REGISTRATION.REQUEST, updateUserNotificationRegistrationAsync);
}
function* watchShowNotification() {
  yield takeEvery(SHOW_NOTIFICATION, showNotificationAsync);
}

export default function* rootSaga() {
  yield all([
    watchLoadUser(),
    watchLoadExtendedInvoices(),
    watchLoadInvoicesList(),
    watchLoadInvoice(),
    watchDeleteInvoice(),
    watchLoadProduct(),
    watchLoadInvoicePdf(),
    watchLoadDeliveryDetails(),
    watchLoadCustomersWithDeliveries(),
    watchLoadCustomerNotifications(),
    watchShowNotification(),
    watchRefreshCustomerNotificationsAsync(),
    watchRegisterUserForNotifications(),
    watchUpdateUserNotificationRegistration()
  ]);
}
