import React from 'react';
import { Row, Col } from 'antd';
import SelectCustomerButton from './SelectCustomerButton';

function CustomerList({ customersWithDeliveries, selectNewCustomer }) {
  const customerList = customersWithDeliveries.map((customer, tileIndex) =>
    customer.customerId ? (
      <SelectCustomerButton
        key={`${customer.customerId}-${customer.opcoId}`}
        tileIndex={tileIndex}
        customerName={customer.customerName}
        address={customer.customerAddress}
        selectNewCustomer={() => selectNewCustomer(customer.customerId, customer.opcoId)}
        deliveryDetails={customer.delivery}
        customerId={customer.customerId}
      />
    ) : null
  );
  return (
    <Row className="section-2 delivery-section">
      <Col span={24} className="delivery-box-wrap">
        {customerList}
      </Col>
    </Row>
  );
}

export default CustomerList;
