import React from 'react';
import { Link } from 'react-router-dom';

export default function NavigationButton({ id, to, name, selectedTab }) {
  return (
    <Link id={id} style={{ textDecoration: 'none' }} to={to}>
      <li className={to === selectedTab ? 'selected tab-selected' : 'selected tab-not-selected'} tabIndex="-1">
        {name}
      </li>
    </Link>
  );
}
