import { Row, Col } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NoDeliveryComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="section-1">
        <Col span={24} className="no-delivery-wrap">
          <div>
            {/* <img src={Truck} alt="truck" /> */}
            <span className="truck"></span>
            <h2 className="no-delivery-h2">{t('delivery.dashboard.noDeliveryMsg')}</h2>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NoDeliveryComponent;
