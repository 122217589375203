import React, { useCallback, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ExtendedInvoices from './ExtendedInvoices';
import TruckLocationComponent from './TruckLocationComponent';
import CustomerDeliveryStatus from './CustomerDeliveryStatus';

function CustomerDeliveryDetails({
  isDeliveryDetailsRefreshing,
  shouldDisplayTruckLocation,
  truckLocation,
  startingDepot,
  stopLocation,
  mapETABanner,
  mapLanguage,
  refreshDeliveryDetails,
  timeSinceLastUpdate,
  lastUpdatedTime,
  deliveryStatusInformation,
  extendedInvoicesProps
}) {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const div = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <Row className="section-1">
        <Col span={14} className="">
          <Row>
            <Row>
              <Col span={8}>
                <Link to="/" className="link-without-text-decoration">
                  <Button type="link btn-invoice-link">
                    <span className="icon-back"></span>
                    <span className="back-text">{t('delivery.delivery.back')}</span>
                  </Button>
                </Link>
              </Col>
              <Col span={16}>
                <div className="last-update">
                  <span className="last-update-text">
                    {timeSinceLastUpdate < 60
                      ? `${t('delivery.dashboard.lastUpdated')} ${timeSinceLastUpdate} ${t(
                          'delivery.dashboard.secondsAgo'
                        )}`
                      : `${t('delivery.dashboard.lastUpdated')} ${Math.floor(timeSinceLastUpdate / 60)} ${
                          Math.floor(timeSinceLastUpdate / 60) > 1
                            ? `${t('delivery.dashboard.minutesAgo')}`
                            : `${t('delivery.dashboard.minuteAgo')} `
                        }`}
                  </span>
                  <Button
                    type="link btn-invoice-link"
                    onClick={refreshDeliveryDetails}
                    loading={isDeliveryDetailsRefreshing}
                  >
                    {t('delivery.dashboard.refresh')}
                  </Button>
                </div>
              </Col>
            </Row>
            <Col span={24} className="delivery-map">
              <div className="section-delivery-map" ref={div}>
                <TruckLocationComponent
                  shouldDisplayTruckLocation={shouldDisplayTruckLocation}
                  truckLocation={truckLocation}
                  startingDepot={startingDepot}
                  stopLocation={stopLocation}
                  mapETABanner={mapETABanner}
                  mapLanguage={mapLanguage}
                  mapHeight={height}
                  mapWidth={width}
                />
              </div>
            </Col>
            <CustomerDeliveryStatus lastUpdatedTime={lastUpdatedTime} {...deliveryStatusInformation} />
          </Row>
        </Col>
        <ExtendedInvoices {...extendedInvoicesProps} />
      </Row>
    </>
  );
}

export default CustomerDeliveryDetails;
