const downloadPDF = (pdf, opco, invoiceNumber) => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = `INV_${opco}_${invoiceNumber}`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export default downloadPDF;
