import React from 'react';
import { Col, List, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import NotificationComponent from './NotificationComponent';

function DeliveryNotificationsComponent({ selectedDelivery, customerName }) {
  const { t } = useTranslation();
  return (
    <Col span={10} id="delivery-invoice-notification" className="delivery-invoice-section">
      <div>
        <Col span={24}>
          <h1>{t('delivery.notifications.deliveryNotifications')}</h1>
          <p className="invoice-title-selected">
            {selectedDelivery.deliveryDate}
            <span className="verticle-divider "></span>
            {customerName}
          </p>
          <hr className="invoice-devider" />
        </Col>
        <Row className="selected-invoice-info">
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={selectedDelivery.notifications}
              renderItem={item => <NotificationComponent notification={item} />}
            />
          </Col>
        </Row>
      </div>
    </Col>
  );
}

export default DeliveryNotificationsComponent;
