import React from 'react';
import AppLoader from 'components/AppLoader';
import { useTranslation } from 'react-i18next';

import NoDeliveryComponent from 'components/NoDeliveryComponent';
import CustomerDeliveryDetails from './CustomerDeliveryDetails';

function DeliveryDetailsPageComponent({
  isInitialDeliveryDetailsFetchUnderway,
  isInitialDeliveryDetailsCompleted,
  isDeliveryDetailsRefreshing,
  shouldDisplayTruckLocation,
  hasNoTodaysDeliveries,
  truckLocation,
  startingDepot,
  stopLocation,
  mapETABanner,
  mapLanguage,
  refreshDeliveryDetails,
  timeSinceLastUpdate,
  lastUpdatedTime,
  deliveryStatusInformation,
  extendedInvoicesProps,
  isLoggedIn
}) {
  const { t } = useTranslation();

  if (isInitialDeliveryDetailsFetchUnderway) return <AppLoader />;

  if (hasNoTodaysDeliveries || !isLoggedIn) return <NoDeliveryComponent />;

  if (isInitialDeliveryDetailsCompleted || !isLoggedIn)
    return (
      <CustomerDeliveryDetails
        truckLocation={truckLocation}
        startingDepot={startingDepot}
        stopLocation={stopLocation}
        mapETABanner={mapETABanner}
        mapLanguage={mapLanguage}
        isDeliveryDetailsRefreshing={isDeliveryDetailsRefreshing}
        shouldDisplayTruckLocation={shouldDisplayTruckLocation}
        refreshDeliveryDetails={refreshDeliveryDetails}
        timeSinceLastUpdate={timeSinceLastUpdate}
        lastUpdatedTime={lastUpdatedTime}
        deliveryStatusInformation={deliveryStatusInformation}
        extendedInvoicesProps={extendedInvoicesProps}
      />
    );

  return <h1>{t('delivery.deliveries.unrecoverableError')}</h1>;
}

export default DeliveryDetailsPageComponent;
