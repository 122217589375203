import { CATEGORY_DIVISION } from 'shared/constants';

export const formattedTotalAmount = (category, esyscoUserType, totalAmount) => {
  if (category === CATEGORY_DIVISION.OUT_OF_STOCK) {
    return null;
  }
  return esyscoUserType ? `$${totalAmount?.toFixed(2)}` : ` `;
};

export const isPartialCategory = (originalOrderQty, orderQty) => {
  return originalOrderQty > 0 && orderQty > 0 && originalOrderQty > orderQty;
};
