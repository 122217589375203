import React from 'react';
import { Collapse, Button } from 'antd';

import AppLoader from 'components/AppLoader';
import CategoryOrdering from 'components/CategoryOrdering';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

function InvoicesExpansion({
  esyscoUserType,
  extendedInvoicesDetails,
  showProductInfo,
  onClickSelectedInvoices,
  activeExpandedInvoices,
  onClickFetchInvoicePdf,
  invoicePdfFetching
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {invoicePdfFetching && <AppLoader show />}
      <Collapse
        className="site-collapse-custom-collapse"
        onChange={onClickSelectedInvoices}
        activeKey={activeExpandedInvoices}
      >
        {extendedInvoicesDetails?.map((extendedInvoiceDetails, invoiceIndex) => {
          const { invoiceNumber, currTransTotAmt, isPdfUrl, transDate, categories } = extendedInvoiceDetails || {};
          if (invoiceNumber)
            return (
              <Panel
                id={`extended-invoice-panel-${invoiceIndex}`}
                header={[
                  <div id={`extended-invoice-header-${invoiceIndex}`} className="header">
                    <div className="deliverymap-invoice-title">{t('delivery.invoice.invoice')}</div>
                    <div className="deliverymap-invoice-number">{`${invoiceNumber}`}</div>
                    <div
                      className={
                        esyscoUserType
                          ? 'deliverymap-invoice-date'
                          : 'deliverymap-invoice-date deliverymap-invoicedate-standard-user'
                      }
                    >
                      {`${transDate}`}
                    </div>
                    <div className="deliverymap-invoice-price">
                      {esyscoUserType ? `$${currTransTotAmt?.toFixed(2)}` : ``}
                    </div>
                    {esyscoUserType && isPdfUrl ? (
                      <Button
                        type="link"
                        className="delivery-map-invoice-download"
                        onClick={event => onClickFetchInvoicePdf(event, invoiceNumber)}
                      >
                        <span className="icon-invoice"></span>
                      </Button>
                    ) : null}
                  </div>
                ]}
                key={invoiceNumber}
              >
                <CategoryOrdering
                  esyscoUserType={esyscoUserType}
                  categories={categories || {}}
                  showProductInfo={showProductInfo}
                />
              </Panel>
            );
          return <React.Fragment />;
        })}
      </Collapse>
    </React.Fragment>
  );
}

export default InvoicesExpansion;
