import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import * as deliveryActions from 'actions';
import GlobalContext from 'context/GlobalContext';
import { DELIVERY_APP_ROUTES, DELIVERY_STATUS_RECEIVED, DELIVERY_TAG_CLASS } from 'shared/constants';
import DashboardPageComponent from './components/DashboardPageComponent';

const lastUpdateTimeInterval = 5000;
const deliveryRefreshInterval = 60 * 5 * 1000;
let refreshTimeUpdate = null;
let deliveryUpdate = null;

const mapDeliveryStatusToUI = (status, t) => {
  let className = null;
  let timeText = null;
  let statusText = null;
  switch (status) {
    case DELIVERY_STATUS_RECEIVED.UPDATED_WINDOW:
      className = DELIVERY_TAG_CLASS.UPDATED_WINDOW;
      statusText = t('delivery.deliveryStatus.enRouteUpdated');
      timeText = t('delivery.delivery.timeWindow.eta');
      break;
    case DELIVERY_STATUS_RECEIVED.DELAYED:
      className = DELIVERY_TAG_CLASS.DELAYED;
      statusText = t('delivery.deliveryStatus.delayed');
      timeText = t('delivery.delivery.timeWindow.eta');
      break;
    case DELIVERY_STATUS_RECEIVED.ONSITE:
      className = DELIVERY_TAG_CLASS.ARRIVED;
      statusText = t('delivery.deliveryStatus.arrived');
      timeText = t('delivery.deliveryTimeText.arrived');
      break;
    case DELIVERY_STATUS_RECEIVED.COMPLETED:
      className = DELIVERY_TAG_CLASS.COMPLETED;
      statusText = t('delivery.deliveryStatus.completed');
      timeText = t('delivery.deliveryTimeText.delivered');
      break;
    case DELIVERY_STATUS_RECEIVED.SCHEDULED:
      className = DELIVERY_TAG_CLASS.SCHEDULED;
      statusText = t('delivery.deliveryStatus.scheduled');
      timeText = t('delivery.delivery.timeWindow.eta');
      break;
    case DELIVERY_STATUS_RECEIVED.ON_TIME:
    default:
      className = DELIVERY_TAG_CLASS.ON_TIME;
      statusText = t('delivery.deliveryStatus.enRoute');
      timeText = t('delivery.delivery.timeWindow.eta');
      break;
  }
  return { className, statusText, timeText };
};

const compareCustomerName = (a, b) => {
  if (a.customerName < b.customerName) {
    return -1;
  }
  if (a.customerName > b.customerName) {
    return 1;
  }
  return 0;
};

const mapCustomerDetailsToUI = (customerList, searchState, t) => {
  // TODO: Move ui mapping part to a separate useEffect and store in container state
  const customersWithDeliveries = customerList
    .map(customer => ({
      ...customer,
      delivery: {
        ...customer.delivery,
        deliveryStatus: mapDeliveryStatusToUI(customer?.delivery?.deliveryStatus, t)
      }
    }))
    .sort(compareCustomerName);

  const filteredList = searchState
    ? customersWithDeliveries?.filter(
        data =>
          data.customerName?.toLowerCase().includes(searchState.toLowerCase()) ||
          data.customerId?.toLowerCase().includes(searchState.toLowerCase())
      )
    : customersWithDeliveries;

  return filteredList || [];
};

function DashboardPageContainer({ customersWithDeliveries, actions }) {
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState(0);
  const [searchState, setSearchState] = useState(null);
  const globalContext = useContext(GlobalContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { userDetails } = useContext(GlobalContext);

  useEffect(() => {
    if (globalContext.isCustomerSelectorVisible) {
      globalContext.setIsCustomerSelectorVisible(false);
    }
  }, [globalContext]);

  const refreshDeliveryDetails = useCallback(() => {
    actions.loadCustomersWithDeliveries(userDetails);
  }, [actions]);

  useEffect(() => {
    if (customersWithDeliveries.data.length > 0) {
      setTimeSinceLastUpdate(0);
      if (refreshTimeUpdate) {
        clearInterval(refreshTimeUpdate);
      }
      refreshTimeUpdate = setInterval(() => {
        setTimeSinceLastUpdate(prevTime => prevTime + 5);
      }, lastUpdateTimeInterval);

      if (deliveryRefreshInterval > 0) {
        if (deliveryUpdate) {
          clearInterval(deliveryUpdate);
        }
        deliveryUpdate = setInterval(refreshDeliveryDetails, deliveryRefreshInterval);
      }
    }

    return () => {
      if (refreshTimeUpdate) {
        clearInterval(refreshTimeUpdate);
        clearInterval(deliveryUpdate);
      }
    };
  }, [customersWithDeliveries]);

  useEffect(() => {
    // TODO: Should the if block check
    // whether the current customer is the same as before?
    actions.loadCustomersWithDeliveries(userDetails);
  }, [actions]);

  const selectNewCustomer = useCallback(
    (customerId, opcoId) => {
      const newCustomer = globalContext.userDetails?.customerList?.find(
        customer => customer.customerId === customerId && customer.opco === opcoId
      );

      if (newCustomer) {
        globalContext.selectCustomer(newCustomer);
        history.push(DELIVERY_APP_ROUTES.DELIVERY_DETAILS);
      } else {
        // TODO: Show error notification
        console.log('user error', globalContext.userDetails);
      }
    },
    [globalContext]
  );

  const { fetching, data: deliveriesData } = customersWithDeliveries || {};

  const isInitialDeliveryDetailsFetchUnderway = deliveriesData?.length === 0 && fetching;
  const isDeliveryDetailsRefreshing = deliveriesData?.length > 0 && fetching;
  const hasNoTodaysDeliveries = deliveriesData.length === 0 && !fetching;
  const isInitialDeliveryDetailsCompleted = deliveriesData?.length > 0;
  const deliveryLength = deliveriesData?.length;

  return (
    <DashboardPageComponent
      hasNoTodaysDeliveries={hasNoTodaysDeliveries}
      isInitialDeliveryDetailsFetchUnderway={isInitialDeliveryDetailsFetchUnderway}
      customersWithDeliveries={mapCustomerDetailsToUI(deliveriesData, searchState, t) || []}
      selectNewCustomer={selectNewCustomer}
      isDeliveryDetailsRefreshing={isDeliveryDetailsRefreshing}
      timeSinceLastUpdate={timeSinceLastUpdate}
      refreshDeliveryDetails={refreshDeliveryDetails}
      isInitialDeliveryDetailsCompleted={isInitialDeliveryDetailsCompleted}
      setSearchState={setSearchState}
      deliveryLength={deliveryLength}
    />
  );
}

const mapStateToProps = state => ({
  customersWithDeliveries: state.customersWithDeliveries
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(deliveryActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageContainer);
