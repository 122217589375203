import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import { GlobalContextProvider } from 'context/GlobalContext';
import * as Authenticator from 'Authenticator';
import store from 'store';
import i18n from './_i18n';
import MainApp from './MainApp';

function App({
  language,
  userDetails,
  selectedCustomer,
  selectCustomer,
  isCustomerSelectorVisible,
  setIsCustomerSelectorVisible,
  Auth
}) {
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    Authenticator.setAuth(Auth);
    return () => {
      Authenticator.setAuth(null);
    };
  }, [Auth]);

  const globalContextValue = {
    language,
    userDetails,
    selectedCustomer,
    selectCustomer,
    isCustomerSelectorVisible,
    setIsCustomerSelectorVisible
  };

  return (
    <Provider store={store}>
      <GlobalContextProvider value={globalContextValue}>
        <MainApp />
      </GlobalContextProvider>
    </Provider>
  );
}

export default App;
