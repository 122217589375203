import React, { useContext, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import * as Authenticator from 'Authenticator';

import GlobalContext from 'context/GlobalContext';
import * as invoicesActions from 'actions';
import { INVOICE_STATUS } from 'shared/constants';
import downloadPDF from 'util/downloadPdf';
import AppLoader from 'components/AppLoader';
import InvoicesList from './components/InvoicesList';

const orderByInvoiceStatusCode = status => {
  if (status === INVOICE_STATUS.PAID) return 2;
  if (status === INVOICE_STATUS.UNPAID) return 1;
  return 0;
};

const InvoicesDetailsContainer = ({
  actions,
  invoicesListfetching,
  invoicesListDetails,
  selectedInvoiceFetching,
  selectedInvoice,
  productFetching,
  productDetails,
  invoicePdfFetching,
  invoicePdf
}) => {
  const globalContext = useContext(GlobalContext);
  const { selectedCustomer, language } = globalContext;
  const { customerId, opco, customerDetails } = selectedCustomer || {};
  const { esyscoUserType } = globalContext.userDetails?.userDetails || {};
  const { invoiceNumber: selectedInvoiceNumber } = selectedInvoice || {};
  const { items: invoiceItems } = invoicesListDetails || {};

  const [selectedInvoiceStatus, setselectedInvoiceStatus] = useState(null);
  const [selectedInvoiceId, setselectedInvoiceId] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [showProduct, setShowProduct] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (Authenticator.isLoggedIn()) {
      actions.loadInvoicesList(customerId, opco, esyscoUserType);
    }
  }, [actions, customerId, opco, esyscoUserType]);

  useEffect(() => {
    if (!globalContext.isCustomerSelectorVisible) {
      globalContext.setIsCustomerSelectorVisible(true);
    }
  }, [globalContext]);

  useEffect(() => {
    if (Authenticator.isLoggedIn()) {
      setselectedInvoiceId(null);
      actions.deleteInvoice();
    }
  }, [actions, customerId, opco, language]);

  const showInvoiceDetails = useCallback(
    (e, record) => {
      e.preventDefault();

      const { customerNumber, opcoNumber, invoiceNum, statusCode } = record;
      setselectedInvoiceId(invoiceNum);
      setShowProduct(false);
      setselectedInvoiceStatus(t(`delivery.invoices.statusCode.${statusCode}`));
      actions.loadInvoice(customerNumber, opcoNumber, invoiceNum, language, esyscoUserType);
    },
    [setShowProduct, setselectedInvoiceStatus, actions, language, esyscoUserType]
  );

  const onClickFetchInvoicePdf = useCallback(
    e => {
      e.preventDefault();
      actions.loadInvoicePdf(selectedInvoiceNumber, opco);
      setShowPdf(true);
    },
    [selectedInvoiceNumber, opco, actions, setShowPdf]
  );

  useEffect(() => {
    if (invoicePdf?.encodedUrl && invoicePdf?.isPdfUrl && showPdf) {
      downloadPDF(invoicePdf?.encodedUrl, opco, selectedInvoiceNumber);
    }
  }, [invoicePdf]);

  const onSelectedInvoice = useCallback(
    record => {
      return {
        onClick: event => showInvoiceDetails(event, record)
      };
    },
    [language]
  );

  const setRowClassName = useCallback(
    record => {
      if (record?.invoiceNum === selectedInvoiceId) return 'invoice-table-tr-active';
      return '';
    },
    [selectedInvoiceId]
  );

  const showProductInfo = useCallback(
    (itemNumber, prodInfo) => {
      setProductInfo(prodInfo);
      setShowProduct(true);
    },
    [customerId, opco, actions, setProductInfo, setShowProduct]
  );

  const closeProductView = useCallback(() => {
    setShowProduct(false);
  }, [setShowProduct]);

  const invoiceListProps = {
    customerDetails,
    esyscoUserType,
    orderByInvoiceStatusCode,
    selectedInvoiceFetching,
    selectedInvoice,
    productFetching,
    productDetails,
    invoicePdfFetching,
    onClickFetchInvoicePdf,
    showProductInfo,
    closeProductView,
    productInfo,
    showProduct,
    selectedInvoiceStatus,
    invoiceItems,
    onSelectedInvoice,
    setRowClassName
  };

  return (
    <React.Fragment>{invoicesListfetching ? <AppLoader /> : <InvoicesList {...invoiceListProps} />}</React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    invoicesListfetching: state.invoicesList.fetching,
    invoicesListDetails: state.invoicesList.data,
    selectedInvoiceFetching: state.invoice.fetching,
    selectedInvoice: state.invoice.data,
    productFetching: state.product.fetching,
    productDetails: state.product.data,
    invoicePdfFetching: state.invoicePdf.fetching,
    invoicePdf: state.invoicePdf.data
  };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(invoicesActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesDetailsContainer);
